body {
  color: #3a453f;
  /* breadcrumb */
  /* breadcrumb */
  /* Heading */
  /* Header */
  /* account page */
  /* account page */
  /* address page */
  /* address page */
  /* Manufacturer Page */
  /* Manufacturer Page */
  /* Category Page */
  /* Category Page */
  /* Compare */
  /* Product Page */
  /* account module */
  /* account module */
  /* category module */
  /* category module */
  /* filter module */
  /* filter module */
  /* showcase */
  /* advancedBanners Module*/
  /* advancedBanners Module*/
}

body a {
  color: #3a453f;
}

body a:hover, body a:focus {
  color: #3a453f;
}

body input:-webkit-autofill {
  background-color: #f5f5f6 !important;
  -webkit-box-shadow: inset 0 0 0 50px #f5f5f6 !important;
}

body select.form-control, body select {
  color: #b9afad;
}

body .form-control {
  border: none;
  background-color: #f5f5f6;
}

body .form-control:focus {
  border-color: transparent;
}

body .btn {
  color: #fff;
}

body .btn:hover, body .btn:focus {
  color: #fff;
}

body .btn-eye {
  color: #f73840;
}

body .btn-eye:hover, body .btn-eye:focus {
  color: rgba(247, 56, 64, 0.8);
}

body .btn-submit, body .btn-round {
  background: #f73840;
}

body .btn-submit:hover, body .btn-round:hover {
  background: rgba(247, 56, 64, 0.8);
}

body .btn-remove .fa {
  color: #f73840;
}

body .btn-remove:hover .fa {
  color: rgba(247, 56, 64, 0.8);
}

body .btn-light {
  background: #b9afad;
}

body .btn-light:hover {
  background: #f73840;
}

body .btn-default-light {
  background: #f5f5f6;
  color: #3a453f;
}

body .btn-default-light:hover {
  background: #f73840;
}

body .btn-default-light:focus {
  background: #f73840;
}

body .btn-cart {
  background: #3a453f;
  color: #fff;
}

body .btn-cart:hover {
  background: rgba(58, 69, 63, 0.8);
}

body .btn-add-cart {
  background: #498f5d;
  color: #fff;
}

body .btn-add-cart:hover {
  background: rgba(73, 143, 93, 0.8);
}

body .btn-menu, body .btn-menu:hover {
  color: #3a453f;
}

body .rating > .fa {
  height: 14px;
  line-height: 14px;
}

body .rating .fa {
  color: #b7232a;
}

body .rating .fa-star + .fa-star-o {
  color: #b7232a;
}

body .link-color {
  color: #498f5d;
}

body .link-color:hover {
  color: rgba(73, 143, 93, 0.8);
}

body .radio-label label .check:before, body .checkbox-label label .check:before {
  background-color: #f73840;
}

body .radio-label label a, body .checkbox-label label a {
  color: #f73840;
}

body .radio-label label a:hover, body .checkbox-label label a:hover {
  color: rgba(247, 56, 64, 0.8);
}

body .radio-label label .check:before, body .checkbox-label label .check:before {
  color: #f73840;
}

body .radio-label label .check:after, body .checkbox-label label .check:after {
  color: #b9afad;
  border-color: #b9afad;
}

body .cart-svg {
  fill: #fff;
}

body .breadcrumb {
  background-color: #f5f5f6;
}

body .breadcrumb > li > a, body .breadcrumb > li > span {
  color: #b9afad;
}

body .breadcrumb > li > a:hover, body .breadcrumb > li > span:hover {
  color: #f73840;
}

body .heading.heading-border {
  border-bottom: 1px solid #e2e2e2;
}

body .heading-module:after {
  background: #e2e2e2;
}

body .heading-module span {
  background: #fff;
}

body .dop-text {
  color: #b9afad;
}

body .dop-text a {
  color: #498f5d;
  text-decoration: none;
}

body .dop-text a:hover {
  color: rgba(73, 143, 93, 0.8);
}

body .navbar-nav > li > a:hover, body .navbar-nav > li > a.active, body .navbar-nav > li > span:hover, body .navbar-nav > li > span.active {
  background: transparent;
  color: #498f5d;
}

body .navbar-nav > li > a:hover:after, body .navbar-nav > li > a.active:after, body .navbar-nav > li > span:hover:after, body .navbar-nav > li > span.active:after {
  color: #3a453f;
}

@media (max-width: 767px) {
  body .navbar-nav > li > a:hover, body .navbar-nav > li > a.active, body .navbar-nav > li > span:hover, body .navbar-nav > li > span.active {
    color: #fff;
  }
}

body .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
  background: #f5f5f6;
  box-shadow: 9px 9px 14px rgba(0, 0, 0, 0.24);
}

@media (max-width: 767px) {
  body .navbar-nav > li.has-child .sub_menu .sub_menu_contein {
    box-shadow: none;
  }
}

body .navbar-nav > li.has-child .sub_menu a:hover, body .navbar-nav > li.has-child .sub_menu a.active {
  color: #498f5d;
}

@media (max-width: 767px) {
  body .navbar-nav > li.has-child .sub_menu a:hover, body .navbar-nav > li.has-child .sub_menu a.active {
    color: #fff;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  body .navbar-nav > li.has-child .sub_menu {
    box-shadow: 9px 9px 14px rgba(0, 0, 0, 0.24);
  }
}

body .main-menu {
  border-top: 1px solid #e2e2e2;
}

@media (max-width: 767px) {
  body .main-menu {
    background: #3a453f;
  }
}

body .btn-search .fa {
  color: #3a453f;
}

body .btn-search:hover .fa {
  color: rgba(58, 69, 63, 0.8);
}

body .search-top input:focus {
  border-color: #498f5d;
}

body .mob-dropdown:before {
  color: #fff;
}

body .dropdown-menu {
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.18);
}

body .dropdown-cart .btn-remove .fa {
  color: #b9afad;
}

body .dropdown-cart .btn-remove .fa:hover {
  color: #f73840;
}

body .dop-menu-dropdown > li > a:hover, body .dop-menu-dropdown > li .btn-menu:hover {
  background: #f5f5f6;
}

@media (max-width: 767px) {
  body .cart-header {
    background: #3a453f;
  }
  body .cart-header .btn-cart {
    background: #fff;
    color: #3a453f;
  }
  body .cart-header .fa {
    color: #fff;
  }
  body .cart-header .fa:hover {
    cursor: pointer;
    opacity: .8;
  }
}

body .close-menu {
  background: #3a453f;
}

body .close-menu .fa {
  color: #fff;
}

body .subtitle.subtitle-border {
  border-bottom: 1px solid #e2e2e2;
}

body .well-border {
  background-color: #fff;
  border: 1px solid #e2e2e2;
}

body legend {
  border-bottom: none;
  font-size: 18px;
}

body .btn-calendar {
  background: #f5f5f6;
}

body .btn-calendar .fa {
  color: #498f5d;
}

body .bootstrap-datetimepicker-widget td.active {
  background: #498f5d;
}

body .bootstrap-datetimepicker-widget td.active:hover {
  background: rgba(73, 143, 93, 0.8);
}

body .bootstrap-datetimepicker-widget td.today:before {
  border-bottom: 7px solid #498f5d;
}

body .bootstrap-datetimepicker-widget td span.active {
  background: #498f5d;
}

body .bootstrap-datetimepicker-widget td span.active:hover {
  background: rgba(73, 143, 93, 0.8);
}

body .table-border thead:first-child > tr:first-child > td, body .table-border tbody:first-child > tr:first-child > td {
  border-top: 1px solid #e2e2e2;
}

body .table-border thead > tr > td, body .table-border tbody > tr > td {
  border-top: 1px solid #e2e2e2;
}

body .table-border .price small {
  color: #b9afad;
}

body .product-quant {
  color: #b9afad;
}

body .img-thumb {
  background: #f5f5f6;
}

body .quantity-contain span {
  background-color: #f5f5f6;
}

body .quantity-contain span:hover .fa {
  color: #f73840;
}

body .module-cart .input-group-btn .btn {
  background: #498f5d;
}

body .module-cart .input-group-btn .btn:hover {
  background: rgba(73, 143, 93, 0.8);
}

body .module-cart.input-group .form-control:focus {
  background: #3a453f;
  color: #fff;
}

body .module-cart.input-group .form-control:focus::placeholder {
  color: #fff;
}

body .module-cart.input-group .form-control:focus:-moz-placeholder {
  color: #fff;
}

body .module-cart.input-group .form-control:focus:-ms-input-placeholder {
  color: #fff;
}

body .module-cart.input-group .form-control:focus::-moz-placeholder {
  color: #fff;
}

body .module-cart.input-group .form-control:focus::-webkit-input-placeholder {
  color: #fff;
}

body .module-cart.input-group .form-control:focus + .input-group-btn {
  color: #fff;
}

body .address-table .text-right a {
  color: #f73840;
}

body .alphabet a {
  color: #f73840;
}

body .alphabet_category {
  border-left: 1px solid #e2e2e2;
}

body .alphabet_list {
  border-bottom: 1px solid #e2e2e2;
}

body .alphabet_list:last-child {
  border-bottom: none;
}

body .alphabet_list a:hover {
  color: #f73840;
}

body .alphabet_list a:before {
  color: #f73840;
}

body .sub_category li {
  background: #f5f5f6;
}

body .sub_category li:hover {
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.24);
}

body .top-sort-panel {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

body .top-sort-panel .btn-view .btn {
  background: transparent;
  color: #b9afad;
}

body .top-sort-panel .btn-view .btn.active, body .top-sort-panel .btn-view .btn:hover {
  color: #498f5d;
}

body .product-list .product-thumb .btn-list .btn-round {
  color: #b9afad;
}

body .product-list .product-thumb .btn-list .btn-round:hover {
  color: #f73840;
}

body .product-list .product-thumb .btn-list .btn-round:hover:after {
  color: #b9afad;
}

body .product-thumb:hover .image {
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.24);
}

body .product-thumb .image {
  background: #f5f5f6;
}

body .product-thumb .product-thumb_manufacturer {
  color: #b9afad;
}

body .price .price-old {
  color: #b9afad;
}

body .show-more, body .show-more:focus, body .show-more:active {
  background: transparent;
  color: #b9afad;
}

body .show-more:hover, body .show-more:focus:hover, body .show-more:active:hover {
  color: #f73840;
}

body .pagination > li > a, body .pagination > li > span {
  background-color: #f5f5f6;
  color: #b9afad;
}

body .pagination > li > a:hover, body .pagination > li > a.active, body .pagination > li > span:hover, body .pagination > li > span.active {
  background-color: #498f5d;
  color: #fff;
}

body .pagination > li.active span, body .pagination > li.active span:hover {
  background-color: #498f5d;
  color: #fff;
}

body .heading-map {
  color: #b9afad;
}

body table .img-thumb {
  background: #f5f5f6;
}

body .table-compare {
  border-bottom: 1px solid #e2e2e2;
}

body .table-compare > tbody > tr > td {
  border-right: 1px solid #e2e2e2;
}

body .table-compare > tbody > tr > td:first-child {
  background: #f5f5f6;
}

body .table-compare .price .price-old {
  color: #b9afad;
}

body .table-compare .btn-remove-compare {
  color: #498f5d;
}

body .table-compare .btn-remove-compare:hover {
  color: rgba(73, 143, 93, 0.8);
}

body table.product-info > tbody > tr > td:first-child {
  color: #b9afad;
}

body .btn-product {
  color: #b9afad;
}

body .btn-product:hover {
  color: #f73840;
}

body .btn-product:hover:after {
  color: #b9afad;
}

body .btn-group-product {
  color: #b9afad;
}

body .beauty_instock {
  color: #498f5d;
}

body .beauty_outstock {
  color: #b9afad;
}

body .product-gallery .thumb {
  background: #f5f5f6;
}

body .flex-tab {
  border-top: 1px solid #e2e2e2;
}

body .flex-tab .nav-tabs > li {
  width: 100%;
  margin-bottom: 0;
}

body .flex-tab .nav-tabs > li.active > a {
  border-bottom: 1px solid #e2e2e2;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  color: #498f5d;
}

body .flex-tab .nav-tabs > li > a {
  border-bottom: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
}

@media (max-width: 767px) {
  body .flex-tab .nav-tabs > li > a {
    border-right: 1px solid #fff;
  }
}

body .flex-tab .nav-tabs > li > a:hover {
  color: #498f5d;
  background: transparent;
}

body .flex-tab .tab-content {
  border-left: 1px solid #e2e2e2;
}

@media (max-width: 767px) {
  body .flex-tab .tab-content {
    border-left: 1px solid #fff;
  }
}

body .col-3 .flex-tab .nav-tabs > li > a {
  border-right: 1px solid #fff;
}

body .col-3 .flex-tab .tab-content {
  border-left: 1px solid #fff;
}

body #column-left .owl-carousel .owl-buttons .owl-next, body #column-left .owl-carousel .owl-buttons .owl-prev, body #column-right .owl-carousel .owl-buttons .owl-next, body #column-right .owl-carousel .owl-buttons .owl-prev, body .showcase-left .owl-carousel .owl-buttons .owl-next, body .showcase-left .owl-carousel .owl-buttons .owl-prev, body .col-3 .owl-carousel .owl-buttons .owl-next, body .col-3 .owl-carousel .owl-buttons .owl-prev {
  color: #fff;
  background: #3a453f;
}

body .owl-carousel .owl-buttons .owl-next, body .owl-carousel .owl-buttons .owl-prev {
  color: #fff;
  background: #3a453f;
}

@media (min-width: 1260px) {
  body .owl-carousel .owl-buttons .owl-next {
    color: #3a453f;
    background: transparent;
  }
  body .owl-carousel .owl-buttons .owl-prev {
    color: #3a453f;
    background: transparent;
  }
}

body .at-share-btn {
  background: #b9afad !important;
}

body .account-module {
  border: 1px solid #f5f5f6;
}

body .account-module .list-group-item:hover {
  color: #498f5d;
}

body .list-module .list-group-item:hover, body .list-module .list-group-item.active {
  color: #498f5d;
}

body .list-group-item {
  background-color: transparent;
}

body .filter .filter-group-item:hover:after {
  color: rgba(58, 69, 63, 0.8);
}

body .filter .all_filter {
  color: #498f5d;
}

body .showcase .nav-tabs {
  border-bottom: none;
}

body .showcase .nav-tabs > li {
  display: inline-block;
  float: none;
}

body .showcase .nav-tabs > li.active > a {
  background: #f5f5f6;
  color: #3a453f;
}

body .showcase .nav-tabs > li > a {
  background: #f5f5f6;
  color: #b9afad;
}

body .showcase .nav-tabs > li > a:hover {
  color: #3a453f;
}

body .showcase_nopadding .caption .button-group, body .showcase_all_nopadding .caption .button-group, body .showcase_gorisontal_view .caption .button-group, body .showcase_gorisontal_view_all .caption .button-group {
  background: #f5f5f6;
}

body .showcase_nopadding [class*="-layout"] .product-thumb:hover .image, body .showcase_all_nopadding [class*="-layout"] .product-thumb:hover .image {
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.24);
}

body .showcase_nopadding [class*="-layout"] .product-thumb .image, body .showcase_all_nopadding [class*="-layout"] .product-thumb .image {
  box-shadow: none;
}

body .content-fullunder .advanced-banners {
  background-color: #f5f5f6;
}

body .advanced-banners .heading-module:after {
  display: none;
}

body .advanced-banners .advanced-column .advanced-carusel:hover {
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.24);
}

body .advanced-banners .advanced-column .container-img {
  background-color: #fff;
}

/* socials module */
.socials .at-share-tbx-element .at-share-btn:hover {
  background: rgba(185, 175, 173, 0.8) !important;
}

.socials .fa {
  color: #f73840;
}

.socials .fa:hover {
  color: rgba(247, 56, 64, 0.8);
}

/* store module*/
.store p {
  color: #3a453f;
}

.store .general a {
  color: #f73840;
}

.store .fa-caret-right {
  color: #f73840;
}

/* store module*/
/* Search-list */
.search-list .dropdown-menu li:hover {
  background-color: #f5f5f6;
}

/* Search-list */
/* Schema module */
#schemes ul li:hover {
  background: #f5f5f6;
}

/* Schema module */
/* Alerts */
.alert-success {
  background-color: #488f5c;
  border: none;
}

.alert-info {
  background-color: #4571a3;
  border: none;
}

.alert-warning {
  background-color: #ff7e1b;
  border: none;
}

.alert-danger {
  background-color: #b7232a;
  border: none;
}

/* Alerts */
/* Checkout */
.order-steps li:first-child:before, .order-steps li:first-child:after {
  background: #f73840;
}

.order-steps li.active:before, .order-steps li.active:after {
  background: #f73840;
}

.order-steps li:before, .order-steps li:after {
  background: #3a453f;
}

/* checkout */
/* magnific-popup */
.mfp-content .mfp-figure .mfp-close {
  color: #3a453f;
}

.mfp-content .mfp-figure .mfp-img {
  background: #fff;
}

.mfp-content .mfp-figure .mfp-bottom-bar .mfp-title {
  color: #3a453f;
}

.mfp-content .mfp-figure .mfp-bottom-bar .mfp-counter {
  color: #3a453f;
}

/* magnific-popup */
/* modal window */
#modal-agree .modal-dialog .modal-content {
  background: #fff;
  color: #3a453f;
  box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.18);
}

#modal-agree .modal-dialog .modal-content .close {
  color: #b7232a;
}

#modal-agree .modal-dialog .modal-content .close:hover {
  color: rgba(183, 35, 42, 0.7);
}

/* modal window */
/* Labels Module*/
.product-labels > li > span {
  background-color: #239b92;
  color: white;
}

/* Labels Module*/
