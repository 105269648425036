$color-1: #3a453f;
$color-2: #498f5d;
$color-3: #f73840;
$color-4: #b9afad;
$color-5: #f5f5f6;
$color-6: #e2e2e2;
$color-7: #fff;
body {
  color: $color-1;
  a{
    color: $color-1;
    &:hover, &:focus{
      color: $color-1;
    }
  }
  input:-webkit-autofill {
    background-color: $color-5 !important;
    -webkit-box-shadow: inset 0 0 0 50px $color-5 !important;
  }
  select.form-control, select{
    color: $color-4;
  }
  .form-control {
    border: none;
    background-color: $color-5;
    &:focus {
      border-color: transparent;
    }
  }
  .btn {
    color: $color-7;
    &:hover, &:focus {
      color: $color-7;
    }
  }
  .btn-eye {
    color: $color-3;
    &:hover, &:focus {
      color: transparentize($color-3, .2);
    } 
  }
  .btn-submit, .btn-round {
    background: $color-3;
    &:hover {
      background: transparentize($color-3, .2);
    }
  }
  .btn-remove{
    .fa{
      color: $color-3;
    }
    &:hover{
      .fa{
        color: transparentize($color-3, .2);
      }
    }
  }
  .btn-light{
    background: $color-4;
    &:hover {
      background: $color-3;
    }
  }
  .btn-default-light{
    background: $color-5;
    color: $color-1;
    &:hover {
      background: $color-3;
    }
    &:focus {
      background: $color-3;
    }
  }
  .btn-cart{
    background: $color-1;
    color: $color-7;
    &:hover{
      background: transparentize($color-1, .2);
    }
  }
  .btn-add-cart{
    background: $color-2;
    color: $color-7;
    &:hover{
      background: transparentize($color-2, .2);
    }
  }
  .btn-menu, .btn-menu:hover{
    color: $color-1;
  }
  .rating{
    &>.fa{
      height: 14px;
      line-height: 14px;
    }
    .fa{
      color: #b7232a;
    }
    .fa-star + .fa-star-o{
      color: #b7232a;
    }
  }
  .link-color {
    color: $color-2;
    &:hover {
      color: transparentize($color-2, .2);
    }
  }
  .radio-label, .checkbox-label {
    label {
      .check:before {
        background-color: $color-3;
      }
      a{
        color: $color-3;
        &:hover{
          color: transparentize($color-3, .2);
        }
      }
    }
  }
  .radio-label, .checkbox-label {
    label {
      .check:before {
        color: $color-3;
      }
      .check:after{
        color: $color-4;
        border-color: $color-4;
      }
    }
  }
  .cart-svg{
    fill: $color-7;
  }
  /* breadcrumb */
  .breadcrumb {
    background-color: $color-5;
    & > li {
      & > a, & > span {
        color: $color-4;
        &:hover {
          color: $color-3;
        }
      }
    }
  }
  /* breadcrumb */

  /* Heading */
  .heading {
    &.heading-border {
      border-bottom: 1px solid $color-6;
    }
  }
  .heading-module{
    &:after{
      background: $color-6;
    }
    span{
      background: $color-7;
    }
  }
  .dop-text {
    color: $color-4;
    a{
      color: $color-2;
      text-decoration: none;
      &:hover{
        color: transparentize($color-2, .2);
      }
    }
  }
  /* Header */
  .navbar-nav{
    &>li{
      &>a, &>span{
        &:hover, &.active{
          background: transparent;
          color: $color-2;
          &:after{
            color: $color-1;
          }
          @media (max-width:767px){
            color: $color-7;
          }
        }
      }
      &.has-child{
        .sub_menu{
          .sub_menu_contein{
            background: $color-5;
            box-shadow: 9px 9px 14px rgba(0, 0, 0, .24);
            @media (max-width:767px){
              box-shadow: none;
            }
          }
          a{
            &:hover, &.active{
              color: $color-2;
              @media (max-width:767px){
                color: $color-7;
              }
            }
          }
          @media (min-width:767px) and (max-width:991px){
              box-shadow: 9px 9px 14px rgba(0, 0, 0, .24);
          }
        }
      }
    }
  }
  .main-menu{
    border-top: 1px solid $color-6;
    @media (max-width:767px){
      background: $color-1;
    }
  }
  .btn-search{
    .fa{
      color: $color-1;
    }
    &:hover{
      .fa{
        color:transparentize($color-1, .2);
      }
    }
  }
  .search-top{
    input {
      &:focus {
        border-color: $color-2;
      }
    }
  }
  .mob-dropdown{
    &:before{
      color: $color-7;
    }
  }
  // dropdown cart
  .dropdown-menu{
    box-shadow: 6px 6px 17px rgba(0,0,0,0.18);
  }
  .dropdown-cart{
    .btn-remove{
      .fa{
        color: $color-4;
         &:hover {
          color: $color-3;
        } 
      }
    }
  }
  //dop-menu-dropdown
  .dop-menu-dropdown{
    &>li {
      & > a, .btn-menu {
        &:hover{
          background: $color-5;
        }
      }
    }
  }
  .cart-header{
    @media (max-width:767px){
      background: $color-1;
      .btn-cart{
        background: $color-7;
        color: $color-1;
      }
      .fa{
        color: $color-7;
        &:hover{
          cursor: pointer;
          opacity: .8;
        }
      }
    }
  }
  .close-menu{
    background: $color-1;
    .fa{
      color: $color-7;
    }
  }

  /* account page */
  .subtitle {
      &.subtitle-border {
        border-bottom: 1px solid $color-6;
      }
    }
  .well-border {
    background-color: $color-7;
    border: 1px solid $color-6;
  }
  legend{
    border-bottom: none;
    font-size: 18px;
  }
  .btn-calendar{
    background: $color-5;
    .fa{
      color: $color-2;
    }
  }
  .bootstrap-datetimepicker-widget td.active {
    background: $color-2;
    &:hover {
      background: transparentize($color-2, .2);
    }
  }
  .bootstrap-datetimepicker-widget td.today {
    &:before {
      border-bottom: 7px solid $color-2;
    }
  }
  .bootstrap-datetimepicker-widget td span.active {
    background: $color-2;
    &:hover {
      background: transparentize($color-2, .2);
    }
  }
  .table-border{
    thead, tbody{
      &:first-child>tr:first-child>td{
        border-top: 1px solid $color-6;
      }
      &>tr>td{
        border-top: 1px solid $color-6;
      }
    }
    .price{
      small {
        color: $color-4;
      } 
    }
  }
  .product-quant{
    color: $color-4;
  }
  .img-thumb{
    background: $color-5;
  }
  .quantity-contain{
    span{
      background-color: $color-5;
      &:hover{
        .fa{
          color: $color-3;
        }
      }
    }
  }
  .module-cart{
    .input-group-btn{
      .btn{
        background: $color-2;
        &:hover{
          background: transparentize($color-2, .2);
        }
      }
    }
    &.input-group .form-control{
      &:focus{
        background: $color-1;
        color: $color-7;
        &::placeholder{
          color: $color-7;
        }
        &:-moz-placeholder{
          color: $color-7;
        }
        &:-ms-input-placeholder{
          color: $color-7;
        }
        &::-moz-placeholder{
          color: $color-7;
        }
        &::-webkit-input-placeholder{
          color: $color-7;
        }
        & + .input-group-btn{
          color: $color-7;
        }
      }
    }
  }
  /* account page */

   /* address page */
  .address-table {
    .text-right {
      a {
        color: $color-3; 
      }
    } 
  }
  /* address page */

  /* Manufacturer Page */
    .alphabet {
      a {
        color:$color-3; 
      }
    }
    .alphabet_category {
        border-left: 1px solid $color-6;
    }
    .alphabet_list {
        border-bottom: 1px solid $color-6;
        &:last-child {
          border-bottom: none;
        }
        a {
          &:hover {
            color: $color-3;
          }
           &:before {
          color: $color-3;
        }
        }
    
  }
  /* Manufacturer Page */

  /* Category Page */
  .sub_category{
    li{
      background: $color-5;
      &:hover{
        box-shadow: 6px 6px 17px rgba(0, 0, 0, .24);
      }
    }
  }
  .top-sort-panel{
    border-top: 1px solid $color-6;
    border-bottom: 1px solid $color-6;
    .btn-view{
      .btn{
        background: transparent;
        color: $color-4;
        &.active, &:hover{
          color: $color-2;
        }
      }
    }
  }
  .product-list{
    .product-thumb{
      .btn-list{
        .btn-round{
          color: $color-4;
          &:hover{
            color: $color-3;
            &:after{
              color: $color-4;
            }
          }
        }
      }
    }
  }
  .product-thumb{
    &:hover{
      .image{
        box-shadow: 6px 6px 17px rgba(0, 0, 0, .24);
      }
    }
    .image{
      background: $color-5;
    }
    .product-thumb_manufacturer{
      color: $color-4;
    }
  }
  .price{
    .price-old{
      color: $color-4;
    }
  }
  .show-more, .show-more:focus, .show-more:active{
    background: transparent;
    color: $color-4;
    &:hover{
      color: $color-3;
    }
  }
  .pagination{
    &>li{
      &>a, &>span{
        background-color: $color-5;
        color: $color-4;
        &:hover, &.active{
          background-color: $color-2;
          color: $color-7;
        }
      }
      &.active{
        span, span:hover{
          background-color: $color-2;
          color: $color-7;
        }
      }
    }
  }
  /* Category Page */
  .heading-map{
    color: $color-4;
  }
  /* Compare */
  table{
    .img-thumb{
      background: $color-5;
    }
  }
  .table-compare{
    border-bottom: 1px solid $color-6;
    &>tbody{
      &>tr{
        &>td{
          border-right: 1px solid $color-6;
          &:first-child{
            background: $color-5;
          }
        }
      }
    }
    .price{
      .price-old{
        color: $color-4;
      }
    }
    .btn-remove-compare{
      color: $color-2;
      &:hover{
        color: transparentize($color-2, .2);
      }
    }
  }
  table.product-info{
    &>tbody{
      &>tr{
        &>td{
          &:first-child {
            color: $color-4;
          }
        }
      }
    }
  }

  /* Product Page */
  .btn-product{
    color: $color-4;
    &:hover{
      color: $color-3;
      &:after{
        color: $color-4;
      }
    }
  }
  .btn-group-product{
    color: $color-4;
  }
  .beauty_instock{
    color: $color-2;
  }
  .beauty_outstock{
    color: $color-4;
  }
  .product-gallery{
    .thumb{
      background: $color-5;
    }
  }
  .flex-tab{
    border-top: 1px solid $color-6;
    .nav-tabs {
      & > li {
        width: 100%;
        margin-bottom: 0;
        &.active {
          & > a {
            border-bottom: 1px solid $color-6;
            border-left: 1px solid #fff;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            color: $color-2;
          }
        }
        & > a {
            border-bottom: 1px solid $color-6;
            border-right: 1px solid $color-6;
            border-left: 1px solid #fff;
            border-top: 1px solid #fff;
          @media (max-width: 767px){
            border-right: 1px solid #fff
          }
          &:hover{
            color: $color-2;
            background: transparent;
          }
        }
      }
    }
    .tab-content{
      border-left: 1px solid $color-6;
      @media (max-width: 767px){
        border-left: 1px solid #fff
      }
    }
  }
  .col-3{
    .flex-tab{
      .nav-tabs {
        & > li {
          & > a {
              border-right: 1px solid #fff
          }
        }
      }
      .tab-content{
          border-left: 1px solid #fff
      }
    }
  }

  #column-left, #column-right, .showcase-left, .col-3{
    .owl-carousel{
      .owl-buttons{
        .owl-next, .owl-prev{
          color: #fff;
          background: $color-1;
        }
      }
    }
  }

  .owl-carousel{
    .owl-buttons{
      .owl-next, .owl-prev{
        color: #fff;
        background: $color-1;
      }
      @media (min-width: 1260px){
        .owl-next{
          color: $color-1;
          background: transparent;
        }
        .owl-prev{
          color: $color-1;
          background: transparent;
        }
      }
    }
  }
  .at-share-btn{
    background: $color-4 !important;
  }

  /* account module */
  .account-module{
    border: 1px solid $color-5;
    .list-group-item{
      &:hover {
        color: $color-2;
      }
    }
  }
  /* account module */

  /* category module */
  .list-module{
    .list-group-item{
      &:hover, &.active{
        color: $color-2;
      }
    }
  }
  .list-group-item{
    background-color: transparent;
  }
  /* category module */

  /* filter module */
  .filter{
    .filter-group-item{
      &:hover{
        &:after{
          color: transparentize($color-1, .2);
        }
      }
    }
    .all_filter{
      color: $color-2;
    }
  }
  /* filter module */

  /* showcase */
  .showcase{
    .nav-tabs{
      border-bottom: none;
      &>li{
        display: inline-block;
        float: none;
        &.active{
          &>a{
            background: $color-5;
            color: $color-1;
          }
        }
        &>a{
          background: $color-5;
          color: $color-4;
          &:hover{
            color: $color-1;
          }
        }
      }
    }
  }
  .showcase_nopadding, .showcase_all_nopadding, .showcase_gorisontal_view, .showcase_gorisontal_view_all{
    .caption {
      .button-group{
        background: $color-5;
      }
    }
  }
  .showcase_nopadding, .showcase_all_nopadding{
    [class*="-layout"] {
      .product-thumb {
        &:hover{
          .image{
            box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.24);
          }
        }
        .image {
          box-shadow: none;
        }
      }
    }
  }
  /* advancedBanners Module*/
  .content-fullunder {
    .advanced-banners {
      background-color: $color-5;
    }
  }
  .advanced-banners{
    .heading-module{
      &:after{
        display: none;
      }
    }
    .advanced-column{
      .advanced-carusel{
        &:hover {
          box-shadow: 6px 6px 17px rgba(0, 0, 0, .24);
        }
      }
      .container-img{
        background-color: $color-7;
      }
    }
  }
  /* advancedBanners Module*/
}

/* socials module */
.socials{
  .at-share-tbx-element .at-share-btn {
      &:hover{
      background: transparentize($color-4, .2)!important;
      }
    }
  .fa{
    color: $color-3;
    &:hover{
      color: transparentize($color-3, .2)
    }
  }
}

/* store module*/
  .store {
    p {
      color: $color-1
    }
    .general {
      a {
        color: $color-3;
      }
    }
    .fa-caret-right {
      color: $color-3;
    }
  }
  /* store module*/

  /* Search-list */

  .search-list {
    .dropdown-menu {  
      li {
        &:hover {
          background-color: $color-5;
        }
      }
    }
  }

  /* Search-list */

  /* Schema module */

    #schemes {
      ul {
        li {
          &:hover {
            background: $color-5;
          }
        }
      }
    }
  
  /* Schema module */

   /* Alerts */

  .alert-success {
    background-color: #488f5c;
    border: none;
  }
  .alert-info {
    background-color: #4571a3;
    border: none;
  }
  .alert-warning {
    background-color: #ff7e1b;
    border: none;
  }
  .alert-danger {
    background-color: #b7232a;
    border: none;
  }
 
  /* Alerts */

  /* Checkout */

   .order-steps {
      li {
        &:first-child:before,
        &:first-child:after {
          background: $color-3
        }
        &.active:before,
        &.active:after {
          background: $color-3
        }
      &:before,
      &:after {
          background: $color-1;
        }
      }
    }

  /* checkout */

  /* magnific-popup */

  .mfp-content .mfp-figure {
    .mfp-close {
      color: $color-1;
    }
    .mfp-img {
      background: $color-7;
    }
    .mfp-bottom-bar {
      .mfp-title {
        color: $color-1;
      }
      .mfp-counter {
        color: $color-1;
      }
    }
  }


  /* magnific-popup */

   /* modal window */

  #modal-agree {
    .modal-dialog {
      .modal-content {
        background: $color-7;
        color: $color-1;
        box-shadow: 6px 6px 17px rgba(0, 0, 0, 0.18);
        .close {
            color: #b7232a;
            &:hover {
              color: transparentize(#b7232a, .3)
            }
          }
      }
    }
  }

  /* modal window */

  /* Labels Module*/
  .product-labels{
    &>li{
      &>span{
        background-color: #239b92;
        color: white;
      }
    }
  }
  /* Labels Module*/